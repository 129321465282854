html * {
	font-family: Ubuntu;
	font-size: 15px;
	box-sizing: border-box;
}
body {
	margin: 0px;

	--color-primary: #202251;
	--color-primary-hover: #343884;
	--color-danger: #ff382c;
	--color-light-blue: #82affb;
	--color-light-blue-2: #82affb;
	--color-light-blue-hover: #4a6fb0;

	--color-main-background: radial-gradient(
			62.29% 62.29% at 50% 50%,
			rgba(142, 202, 230, 0.5) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#ffffff;
	--color-background: #ffffff;
	--color-background-2: #ffffff;
	--color-background-3: #ffffff;
	--color-background-4: #e9e9e9;
	--color-background-5: #eeeeee;
	--color-background-6: #ffffff;
	--color-background-7: #e9ecef;
	--color-background-8: #e3e8ff;
	--color-background-9: rgb(130, 175, 251);
	--color-background-10: #f3f3f3;
	--color-background-11: #e0e0e0;
	--color-background-12: #ffffff;
	--color-background-13: #a6a6ba;
	--color-background-tooltip: #202251;

	--color-text: #000000;
	--color-text-2: #202251;
	--color-text-3: #b2b2b2;
	--color-text-4: #767676;
	--color-text-5: #838386;
	--color-text-6: #202351;
	--color-text-7: #7a7a7a;
	--color-text-8: #26A17B;
	--color-text-9: #ffffff;
	--color-text-primary: rgba(32, 34, 81, var(--text-opacity));
	--color-text-btn: #9e9e9e;
	--color-text-btn-2: #bdbdbd;
	--color-text-btn-3: #9e9e9e;
	--color-text-btn-hover: #ffffff;
	--color-text-btn-hover-2: #e9e9e9;
	--color-text-btn-hover-3: #e7ebee;
	--color-text-gray-1: #969696;
	--color-text-gray-2: #7d7d7d;
	--color-text-gray-3: #8f8f8f;
	--color-text-gray-4: #828282;
	--color-text-gray-5: #605f5f;
	--color-text-gray-6: #4F4F4F;
	--color-text-gray-7: #9d9d9d;
	--color-text-invert: #ffffff;
	--color-text-navbar-disabled: #838386;

	--color-modal-text-title: #495057;
	--color-modal-dismiss: #6c757d;
	--color-modal-dismiss-hover: #6c757d;

	--color-border: #eaeaea;
	--color-border-2: #eaeaea;
	--color-border-3: #202251;
	--color-border-4: #202251;
	--color-border-5: #e0e0e0;
	--color-border-6: rgb(73, 80, 87);
	--color-border-7: #dbdbdb;
	--color-border-8: #eaeaea;

	--color-hover: rgba(32, 34, 81, 0.15);

	--color-switch: #202251;
	--color-switch-2: #d8d8d8;

	&[data-theme='space'] {
		--color-primary: #ffffff;
		--color-primary-hover: #eaeaea;
		--color-danger: #ff382c;
		--color-light-blue: #82affb;
		--color-light-blue-2: #ffffff;
		--color-light-blue-hover: #4a6fb0;

    	--color-main-background: url('../images/background-space.svg'), #202251;
		--color-background: #2d2f60;
		--color-background-2: #202251;
		--color-background-3: #3c3e77;
		--color-background-4: #484868;
		--color-background-5: #3c3e77;
		--color-background-6: #202251;
		--color-background-7: #202251;
		--color-background-8: #3c3e77;
		--color-background-9: #ffffff;
		--color-background-10: #202251;
		--color-background-11: #2d2f60;
		--color-background-12: #ffffff;
		--color-background-13: #1F2250;

		--color-text: #ffffff;
		--color-text-2: #ffffff;
		--color-text-3: #ffffff;
		--color-text-4: #ffffff;
		--color-text-5: #ffffff;
		--color-text-6: #ffffff;
    --color-text-7: #ffffff;
		--color-text-9: #c6c6c6;
		--color-text-primary: #ffffff;
		--color-text-btn: #e9e9e9;
		--color-text-btn-2: #a0a0a0;
		--color-text-btn-3: #2d2f60;
		--color-text-btn-hover: #2d2f60;
		--color-text-btn-hover-2: #48486b;
		--color-text-btn-hover-3: #3c3e77;
		--color-text-gray-1: #ffffff;
		--color-text-gray-2: #ffffff;
		--color-text-gray-3: #ffffff;
    	--color-text-gray-4: #ffffff;
    	--color-text-gray-5: #ffffff;
    	--color-text-gray-6: #ffffff;
    	--color-text-gray-7: #ffffff;
		--color-text-invert: #202251;
		--color-text-navbar-disabled: #ffffff;

		--color-modal-text-title: #ffffff;
		--color-modal-dismiss: #ffffff;
		--color-modal-dismiss-hover: #2d2f60;

		--color-border: #202251;
		--color-border-2: #3c3e77;
		--color-border-3: #202251;
		--color-border-4: #3c3e77;
		--color-border-5: #3c3e77;
		--color-border-6: #ffffff;
		--color-border-7: #3c3e77;
		--color-border-8: #4d5092;

    --color-hover: rgba(32, 34, 81, 0.5);

		--color-switch: #82affb;
		--color-switch-2: #48486b;
	}

	background: var(--color-background-2);
}
#app {
	background: var(--color-main-background);
}
.is-desktop {
	@media (max-width: 769px) {
		display: none !important;
	}
}
.is-mobile {
	@media (min-width: 768px) {
		display: none !important;
	}
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu-Regular.ttf') format('truetype');
	font-style: normal;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Ubuntu;
	src: url('../fonts/Ubuntu-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

input {
	background: var(--color-background-2);
	border: 1px solid var(--color-border-3);
}

.text-primary {
	color: var(--color-text-primary) !important;
}

.p-sidebar {
	background-color: var(--color-background) !important;
}

.p-sidebar-content {
	margin-top: 50px !important;
}

.p-sidebar-right {
	width: 12rem !important;
}

.p-dialog .p-dialog-header {
	background-color: var(--color-background) !important;
	color: var(--color-modal-text-title) !important;
}

.p-dialog .p-dialog-content {
	background-color: var(--color-background) !important;
	color: var(--color-modal-text-title) !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
	color: var(--color-modal-dismiss) !important;

	&:hover {
		color: var(--color-modal-dismiss-hover) !important;
	}
}

.p-sidebar .p-sidebar-close {
  color: var(--color-modal-dismiss) !important;

  &:hover {
    color: var(--color-modal-dismiss-hover) !important;
  }

	&:focus {
		box-shadow: unset !important;
	}
}

.p-button.p-button-text.p-button-plain:enabled {
	&:hover {
		background-color: var(--color-background-7) !important;
	}
}
